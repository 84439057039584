import React, { useState } from 'react';
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";

const UrlUpload = ({
  placeholder,
  onChange,
  handleUpload,
  value,
  errorMessage,
  disable = false,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const onUploadClick = async () => {
    if(!value) {
      return;
    }
    try {
      // Call the original handleUpload function
      await handleUpload();
      
      // Set uploading state to true
      setIsUploading(true);
      
      // After 2 seconds, reset the uploading state
      setTimeout(() => {
        setIsUploading(false);
      }, 2000);
    } catch (error) {
      // Handle any potential errors from handleUpload
      console.error('Upload failed', error);
      setIsUploading(false);
    }
  };

  return (
    <FormControl sx={{ width: "100%", mb: "10px" }} variant="outlined">
      <OutlinedInput
        size="small"
        disabled={disable}
        sx={{
          height: "32px",
          "& input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            padding: "8px",
          },
          "& fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
          },
          "&.Mui-focused fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
          },
          "&.Mui-disabled": {
            opacity: 1,
            "& fieldset": {
              borderColor: "#B5BBC5",
            },
          },
        }}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        error={!!errorMessage}
        endAdornment={
          <InputAdornment position="end">
            <Button
              color="inherit"
              onClick={onUploadClick}
              disabled={disable || isUploading}
              sx={{
                marginRight: "-13px",
                backgroundColor: "#F9F9FC",
                color: disable || isUploading ? "#B5BBC5" : "#003D86",
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                borderRadius: "4px",
                height: "32px",
                width: "92px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                alignItems: "center",
                textTransform: "none",
              }}
              size="small"
            >
              {isUploading ? "Uploaded" : "Upload"}
            </Button>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            fontSize: "12px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {errorMessage}
        </Box>
      )}
    </FormControl>
  );
};

export default UrlUpload;