import React from "react";
import { TextField, InputAdornment, Box } from "@mui/material";

const MuiTextarea = ({
  onChange,
  placeholder,
  minRows = 3,
  value = "", // Provide a default value
  errorMessage = "",
  editBoolean,
  isDisable,
  maxLength,
}) => {
  const handleChange = (inputValue) => {
    if (maxLength) {
      onChange(inputValue.slice(0, maxLength));
    } else {
      onChange(inputValue);
    }
  };

  // Ensure value is always a string
  const stringValue = value ? value.toString() : "";

  return (
    <Box position="relative" sx={{ width: "100%" }}>
      <TextField
        sx={{
          width: "100%",
          mt: "10px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: editBoolean ? "orange" : "",
              borderRadius: "6px",
            },
            "&:hover fieldset": {
              borderColor: editBoolean ? "orange" : "",
            },
            "&.Mui-focused fieldset": {
              borderColor: editBoolean ? "orange" : "",
            },
            "&.Mui-disabled": {
              opacity: 1,
              "& fieldset": {
                borderColor: editBoolean ? "orange" : "",
              },
            },
          },
          "& .MuiInputBase-input::placeholder": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            whiteSpace: "pre-line",
          },
          "& .MuiInputBase-input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: "#000000ba !important",
            backgroundColor: "transparent",
          },
          "& .MuiInputAdornment-root": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "13px",
            color: "#B5BBC5",
          },
        }}
        disabled={isDisable}
        multiline
        fullWidth
        size="small"
        minRows={minRows}
        placeholder={placeholder}
        value={stringValue}
        onChange={(evt) => handleChange(evt.target.value)}
        helperText={errorMessage}
        error={!!errorMessage}
        inputProps={{
          maxLength: maxLength,
        }}
      />
      {maxLength && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            fontSize: "10px",
            color: "#B5BBC5",
            padding: "4px 8px",
          }}
        >
          {`${stringValue.length}/${maxLength}`}
        </Box>
      )}
    </Box>
  );
};

export default MuiTextarea;
