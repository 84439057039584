import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddColumnIcon from "../assets/AddColumnIcon.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "./MuiButton";
import { Box, Typography } from "@mui/material";

const ToggleColumn = ({
  tableFields = [],
  handleToggleColumn,
  disabledIndex = 0,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MuiButton
        text=" Add Column"
        variant="outlined"
        style={{
          padding: "0px",
          border: "1px solid rgba(0, 0, 0, 0.30)",
          marginBottom: "10px",
          height: "30px",
          width: "85px",
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "18px",
          color: "#667085",
        }}
        onClick={handleClick}
        icon={AddColumnIcon}
        bg="white"
        color="black"
      >
        Column
      </MuiButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <FormGroup
        // onClick={handleChange}
        >
          {tableFields?.map((options, index) => {
            // console.log(options,"options" )
            return (
              <Box
                sx={{
                  borderBottom: "1px solid #020d20",
                  margin: "0",
                  padding: "10px 10px 10px 0px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleToggleColumn(index)}
              >
                <Checkbox
                  name={options.name}
                  sx={{
                    color: "#003D86",
                    "&.Mui-checked": {
                      color: "#003D86",
                    },
                    "&.Mui-disabled": {
                      color: "gray",
                    },
                    height: "15px",
                    width: "15px",
                    margin: "0px 10px",
                  }}
                  // onClick={() => handleToggleColumn(index)}
                  checked={options?.flag}
                  disabled={index < disabledIndex}
                />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    // color: "#667085",
                  }}
                >
                  {options?.displayName}
                </Typography>
              </Box>
            );
          })}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default ToggleColumn;
