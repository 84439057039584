import React from "react";
import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Button,
  Box,
  Typography,
} from "@mui/material";

const UrlUpload = ({
  placeholder,
  onChange,
  handleUpload,
  value,
  errorMessage,
  disable = false, // New disable prop
}) => {
  return (
    <FormControl sx={{ width: "100%", mb: "10px" }} variant="outlined">
      <OutlinedInput
        size="small"
        disabled={disable} // Apply disable prop to OutlinedInput
        sx={{
          height: "32px",
          "& input": {
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            padding: "8px",
          },
          "& fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
            borderRadius: "6px",
          },
          "&:hover fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
          },
          "&.Mui-focused fieldset": {
            borderColor: disable ? "#B5BBC5" : errorMessage ? "#d74848" : "",
          },
          "&.Mui-disabled": {
            opacity: 1,
            "& fieldset": {
              borderColor: "#B5BBC5", // Disabled border color
            },
          },
        }}
        onChange={(e) => onChange(e?.target?.value)}
        value={value}
        error={!!errorMessage}
        endAdornment={
          <InputAdornment position="end">
            <Typography
              color="inherit"
              onClick={handleUpload}
              disabled={disable} // Disable button if prop is true
              sx={{
                marginRight: "-13px",
                backgroundColor: "#F9F9FC",
                color: disable ? "#B5BBC5" : "#003D86", // Change color when disabled
                // padding: "6px 12px", // Adjust padding to fit
                fontFamily: "Lato",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "16px",
                borderRadius: "4px", // Add border radius for a smoother look
                height: "32px", // Match the height of OutlinedInput
                width:"92px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // "&:hover": {
                //   backgroundColor: disable ? "#F9F9FC" : "#e0e0e0", // Lighter on hover, if not disabled
                // },
              }}
              size="small"
            >
              Upload
            </Typography>
          </InputAdornment>
        }
        placeholder={placeholder}
      />
      {errorMessage && (
        <Box
          sx={{
            mt: 1,
            fontSize: "12px",
            color: "#d74848",
            textAlign: "left",
            ml: "20px",
          }}
        >
          {errorMessage}
        </Box>
      )}
    </FormControl>
  );
};

export default UrlUpload;
