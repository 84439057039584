import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoldLabel from "../../UIComponents/BoldLevel";
import MuiInput from "../../UIComponents/MuiInput";
import MuiTextarea from "../../UIComponents/MuiTextarea";
import UserCohortsFileUpload from "../../components/userCohorts/UserCohortsFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import arrow from "../../assets/arrow-left.svg";
import { resetCreateCohortData, setCreateCohortChanges } from "../../Redux/CreateRuleReducer/actionType";
import MuiSelectAndSearch from "../../UIComponents/MuiSelectAndSearch";
import UrlUpload from "../../UIComponents/UrlUpload";
import MuiSelect from "../../UIComponents/MuiSelect";
import deleteLogo from "../../assets/redDeleteBtn.png";
import addLogo from "../../assets/add.svg";
import { FaPlus } from "react-icons/fa6";
import { getToken } from "../../utils/token";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
// import { toast, Toaster } from "sonner";

const options = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];

const options2 = [
  { id: "AND", name: "AND" },
  { id: "OR", name: "OR" },
];

export default function CreateCohortPage() {
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    cohortActionType: "",
    csvFile: "",
    whereSection: []
  });
  const data = useSelector((store) => store.CreateRuleReducer.createCohortData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname , state } = useLocation();
  const token = getToken();

// Add this validation function before handleData
const validateForm = () => {
  let isValid = true;
  const newErrors = {
    name: "",
    description: "",
    cohortActionType: "",
    csvFile: "",
    whereSection: []
  };

  // Validate name
  if (!data.name || data.name.trim() === "") {
    newErrors.name = "Cohort name is required";
    isValid = false;
  } else if (data.name.length > 40) {
    newErrors.name = "Name cannot exceed 40 characters";
    isValid = false;
  }

  // Validate description
  if (!data.description || data.description.trim() === "") {
    newErrors.description = "Description is required";
    isValid = false;
  } else if (data.description.length > 200) {
    newErrors.description = "Description cannot exceed 200 characters";
    isValid = false;
  }

  // Validate cohort action type
  if (!data.cohortActionType?.id) {
    newErrors.cohortActionType = "Please select a cohort action type";
    isValid = false;
  }

  // Validate CSV file for STATIC type
  if (data.cohortActionType?.id === "STATIC" && !data.csvFile) {
    newErrors.csvFile = "Please upload a CSV file";
    isValid = false;
  }

  setErrors(newErrors);
  return isValid;
};

// Update handleData function to clear errors
const handleData = (key, value) => {
  // Clear error when field is updated
  setErrors(prev => ({
    ...prev,
    [key]: ""
  }));

  if (key === "csvFile" && value) {
    if (value.type !== "text/csv") {
      toast.error("Please select a CSV file.");
      return;
    }
  }
  
  dispatch({
    type: setCreateCohortChanges,
    payload: { [key]: value },
  });
};

  const handleWhereSectionChange = (index, groupIndex, key, value) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data[groupIndex][key] = value;
    handleData("whereSection", updatedWhereSection);
  };

  const handleAddCriteria = () => {
    const newCriteria = {
      data: [
        {
          role: "",
          operator: "",
          value: "",
          divider: "AND",
        },
      ],
      type: "AND",
    };
    handleData("whereSection", [...data.whereSection, newCriteria]);
  };

  const handleDeleteCriteria = (index) => {
    if (data.whereSection.length > 1) {
      const updatedWhereSection = [...data.whereSection];
      updatedWhereSection.splice(index, 1);
      handleData("whereSection", updatedWhereSection);
    }
  };

  const handleAddGroup = (index) => {
    const newGroup = {
      role: "",
      operator: "",
      value: "",
      divider: "AND",
    };
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = [
      ...updatedWhereSection[index].data,
      newGroup,
    ];
    handleData("whereSection", updatedWhereSection);
  };

  const handleDeleteGroup = (index, groupIndex) => {
    const updatedWhereSection = [...data.whereSection];
    updatedWhereSection[index].data = updatedWhereSection[index].data.filter(
      (_, i) => i !== groupIndex
    );
    handleData("whereSection", updatedWhereSection);
  };

  const uploadCsvFile = async (file, json_data, type) => {
    if (!token) return false;
    const url = `${process.env.REACT_APP_API_BASE_URL}/api/admin/create-cohorts`;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    // if (type === "STATIC") {
      formData.append("CollectionItemsCSV", file);
    // }
    formData.append("json_data", JSON.stringify(json_data));

    try {
      console.log(formData, "formData");
      const response = await axios.post(url, formData, options);
      console.log(response, "response");
      setTimeout(() => {
        if(state?.id ==="offer"){
          navigate(-1)
        }else{
          navigate("/userCohorts-view");
        }
      }, 2000);
      if (response.status === 200) {
        // Proceed with your logic here
        return true;
        // You can access response data if needed like: response.data
      }
    } catch (error) {
      console.log("Error While Uploading File", error);
      toast.error(error.response?.data?.message);
      return false;
    }
  };

  const handleCreateCohort = async () => {
    // if (!validateForm()) {
    //   toast.error("Please fix the validation errors before saving");
    //   return;
    // }
  
    const name = data.name;
    const description = data.description;
    const type = data?.cohortActionType?.id;
    const csvFile = data?.csvFile;
    const json_data = {
      name,
      description,
      type,
    };
  // Validation logic for empty fields, showing only the first error
  if (!name) {
    toast.error("Name is required.");
    return;
  }
  if (!description) {
    toast.error("Description is required.");
    return;
  }
  if (!type) {
    toast.error("Cohort Type is required.");
    return;
  }
  if (!csvFile) {
    toast.error("CSV file is required.");
    return;
  }
    const FileUpload = await uploadCsvFile(csvFile, json_data, type);

    if (!FileUpload) return;

    toast.success("Cohort Created Successfully");
  };
  console.log(data, "cohortData");

  useEffect(()=>{
    dispatch({
      type: setCreateCohortChanges,
      payload: {
        name: "",
        description: "",
        cohortActionType: null,
        csvFile: null,
        whereSection: [{
          data: [{
            role: "",
            operator: "",
            value: "",
            divider: "AND"
          }],
          type: "AND"
        }]
      }
    });
  },[])
 
  return (
    <Box
      sx={{
        margin: "20px",
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        minHeight: "calc(100vh - 130px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <ToastContainer />
        {/* <Toaster position="top-right"  richColors closeButton={true} toastOptions={{ style: {padding:"15px 10px" }, } }/> */}
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            sx={{ minWidth: "0px" }}
            // onClick={() =>
            //   navigate("/offers/create-offers/set-qualifying-criteria")
            // }
            onClick={() => navigate(-1)}
          >
            <img style={{ height: "16px", width: "16px" }} src={arrow} alt="" />
          </Button>
          <Typography
            sx={{
              fontFamily: "Lato",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Create New Cohort
          </Typography>
        </Box>

        <Box>
          <Box sx={{ width: "500px" ,mb:errors?.name ? "40px" : "0px"}}>
            <BoldLabel label="Cohort Name" />
            <MuiInput
              value={data?.name}
              onChange={(e) => handleData("name", e)}
              placeholder="50 characters"
              errorMessage={errors.name}
              maxLength={50}
            />
          </Box>
          <Box sx={{ width: "500px" }}>
            <BoldLabel label="Cohort Description" />
            <MuiTextarea
              value={data.description}
              onChange={(e) => handleData("description", e)}
              placeholder="200 characters"
              errorMessage={errors.description}
              maxLength={200}
            />
          </Box>
          <Box sx={{ width: "500px" }}>
            <BoldLabel label="Select Cohort Action" />
            <MuiSelectAndSearch
              options={[
                // { name: "Cohort Rule", id: "DYNAMIC" },
                { name: "Upload CSV", id: "STATIC" },
              ]}
              value={data.cohortActionType}
              onChange={(value) => handleData("cohortActionType", value)}
              placeholder="Search / Select"
              errorMessage={errors.cohortActionType}
            />
          </Box>

          <Box
            sx={{
              width: "500px",
              mt: "20px",
              display:
                data.cohortActionType?.id === "STATIC" ? "block" : "none",
            }}
          >
            <UserCohortsFileUpload
              data={data}
              // errorMessage={error}
              onChange={(key, value) => handleData(key, value)}
              errorMessage={errors.csvFile}
            />

            {/* <BoldLabel label="Or Upload from URL" mb="8px" />
          <UrlUpload
            placeholder="Add file URL"
            handleUpload={() => null}
            value={data?.CsvUrl}
            onChange={(value) => handleData("CsvUrl", value)}
          /> */}
          </Box>

          <Box
            sx={{
              display:
                data?.cohortActionType?.id === "DYNAMIC" ? "block" : "none",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0B1B32",
                marginTop: "25px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              Where
            </Typography>

            <Box>
              {data?.whereSection?.map((item, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      display: index === 0 ? "none" : "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "26px",
                      borderRadius: "8px",
                      padding: "14px",
                      width: "calc(100%-48px)",
                      mt: "14px",
                      mb: "19px",
                      height: "32px",
                    }}
                  >
                    <Box
                      sx={{ border: "1px solid #0B1B32", width: "100%" }}
                    ></Box>
                    <Box sx={{ width: "180px" }}>
                      <MuiSelect
                        backgroundColor="black"
                        selectedBackgroundColor="black"
                        textColor="white"
                        selectedTextColor="white"
                        hoverBackgroundColor="gray"
                        options={options}
                        value={item.type}
                        onChange={(e) =>
                          handleData("whereSection", [
                            ...data.whereSection.slice(0, index),
                            { ...item, type: e },
                            ...data.whereSection.slice(index + 1),
                          ])
                        }
                      />
                    </Box>
                    <Box
                      sx={{ border: "1px solid #0d1520", width: "100%" }}
                    ></Box>
                  </Box>

                  <Box
                    mb="20px"
                    sx={{
                      width: "calc(100%-28px)",
                      backgroundColor: "#F4F8FC",
                      borderRadius: "8px",
                      padding: "14px",
                    }}
                  >
                    {item?.data?.length > 0 &&
                      item?.data?.map((group, groupIndex) => (
                        <Box key={groupIndex}>
                          <Box
                            sx={{
                              display: groupIndex === 0 ? "none" : "flex",
                              alignItems: "center",
                              width: "calc(100%-68px)",
                              gap: "26px",
                              mt: "14px",
                              mb: "19px",
                              height: "32px",
                            }}
                          >
                            <Box
                              sx={{
                                border: "1px solid #9CBFEB",
                                width: "100%",
                              }}
                            ></Box>
                            <Box sx={{ width: "180px" }}>
                              <MuiSelect
                                options={options}
                                value={group.divider}
                                textColor="#003D86"
                                backgroundColor="#E8F0F9"
                                onChange={(e) =>
                                  handleWhereSectionChange(
                                    index,
                                    groupIndex,
                                    "divider",
                                    e
                                  )
                                }
                              />
                            </Box>
                            <Box
                              sx={{
                                border: "1px solid #9CBFEB",
                                width: "100%",
                              }}
                            ></Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "20px",
                              alignItems: "end",
                              width: "calc(100%-56px)",
                              backgroundColor:
                                item.data.length > 1 ? "#D5E8F6" : "",
                              padding: item.data.length > 1 ? "14px" : "",
                              borderRadius: "8px",
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <BoldLabel label="Role" mt="0px" />
                              <MuiSelectAndSearch
                                options={options2}
                                value={group.role}
                                onChange={(e) =>
                                  handleWhereSectionChange(
                                    index,
                                    groupIndex,
                                    "role",
                                    e
                                  )
                                }
                              />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <BoldLabel label="Operator" mt="0px" />
                              <MuiSelectAndSearch
                                options={options2}
                                value={group.operator}
                                onChange={(e) =>
                                  handleWhereSectionChange(
                                    index,
                                    groupIndex,
                                    "operator",
                                    e
                                  )
                                }
                              />
                            </Box>
                            <Box sx={{ width: "100%", mb: "4px" }}>
                              <BoldLabel label="Add Value" mt="0px" />
                              <MuiInput
                                value={group.value}
                                onChange={(e) =>
                                  handleWhereSectionChange(
                                    index,
                                    groupIndex,
                                    "value",
                                    e
                                  )
                                }
                                placeholder=""
                              />
                            </Box>
                            <Button
                              sx={{
                                minWidth: "40px",
                                height: "40px",
                                borderRadius: "6px",
                                backgroundColor: "#FC55551A",
                                mb: "4px",
                                display:
                                  item.data.length > 1 ? "block" : "none",
                              }}
                              onClick={() =>
                                handleDeleteGroup(index, groupIndex)
                              }
                            >
                              <img
                                style={{ height: "25px", width: "25px" }}
                                src={deleteLogo}
                                alt=""
                              />
                            </Button>
                            <Box sx={{ ml: "5%", mb: "4px" }}>
                              <Button onClick={() => handleAddGroup(index)}>
                                <img src={addLogo} alt="" />
                                &nbsp;
                                <Typography
                                  sx={{
                                    fontFamily: "Lato",
                                    fontWeight: 700,
                                    fontSize: "12px",
                                    lineHeight: "14.4px",
                                  }}
                                >
                                  Add
                                </Typography>
                                &nbsp;
                                <Typography
                                  sx={{
                                    fontFamily: "Lato",
                                    fontWeight: 700,
                                    fontSize: "12px",
                                    lineHeight: "14.4px",
                                  }}
                                >
                                  Group
                                </Typography>
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "14px" }}
            >
              <Button
                sx={{
                  width: "151px",
                  height: "28px",
                  backgroundColor: "#003D86",
                  borderRadius: "6px",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor: "#003D86",
                  },
                }}
                onClick={handleAddCriteria}
              >
                <FaPlus />
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "12px",
                    lineHeight: "14.4px",
                    fontWeight: 500,
                    marginLeft: "6px",
                    textTransform: "capitalize",
                  }}
                >
                  Create New Criteria
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          mt: "20px",
        }}
      >
        <Button
          sx={{
            color: "#003D86",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#E8F0F9",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#E8F0F9",
            },
            display: pathname.includes("offers") ? "block" : "none",
          }}
          // onClick={() => navigate("/offers/create-offers/set-qualifying-criteria")}
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            color: "#FFFFFF",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "16.8px",
            backgroundColor: "#003D86",
            width: "136px",
            height: "30px",
            borderRadius: "6px",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#003D86",
            },
          }}
          onClick={handleCreateCohort}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}
