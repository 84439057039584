import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HorizontalLinearStepper from "./create-game-components/Stepper";
import AllGames from "./create-game-components/AllGames";
import GameType from "./create-game-components/GameType";
import BeforePlayPage from "./create-game-components/BeforePlayPage";
import AfterPlayPage from "./create-game-components/AfterPlayPage";
import {
  getAllGameTypes,
  getAllRewardFunction,
  getRewardTypesFunction,
} from "../../../Redux/OfferReducer/action";
import { DeviceFrameset } from "react-device-frameset";
import editLogo from "../../../assets/Pen.png";
import { AndroidMockup } from "react-device-mockup";
import previous_image from "../../../assets/previous_image.svg";
import { IoIosArrowBack } from "react-icons/io";
import transparent_background from "../../../assets/transparent_background.svg";
import { toast, ToastContainer } from "react-toastify";

const steps = ["Game Type", "Add Reward", "Before Play", "After Play"];

export default function CreateGamePage({ showEdit, disableEditButton }) {
  const [activeStep, setActiveStep] = useState(0);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [openRewardIndex, setOpenRewardIndex] = useState(null);
  const [beforePlayErrorMessage, setBeforePlayErrorMessage] = useState({});
  const [addRewardErrorMessage, setAddRewardErrorMessage] = useState([]);
  const [afterPlayErrorMessage, setAfterPlayErrorMessage] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const data = useSelector((store) => store.OfferReducer.offerData);
  const beforePlayValidationObject = {};
  const afterPlayValidationObject = [];
  const addRewardValidationObject = [];
  const gameDataName =
    data?.createGame?.gameName === "Spin The Wheel"
      ? "spinTheWheelData"
      : data?.createGame?.gameName === "Slot Machine"
      ? "slotMachineData"
      : data?.createGame?.gameName === "Scratch Card"
      ? "scratchCardData"
      : data?.createGame?.gameName === "Trivia"
      ? "triviaData"
      : "";
  console.log(afterPlayErrorMessage, "afterPlayErrorMessage");
  const { id } = useParams();
  
const transformFieldName = (fieldName) => {
  // Replace underscores with spaces and split into words
  const words = fieldName.replace(/_/g, ' ').split(' ');
  
  // Capitalize first letter of each word
  return words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const validateBeforePlayData = () => {
  setBeforePlayErrorMessage({});
  const beforePlayData = data?.createGame?.beforePlay?.[gameDataName];
  
  if (!beforePlayData) {
    toast.error("Before Play data is missing");
    return false;
  }

  const missingFields = [];
 
  // Check each mandatory field in the validation object
  Object.entries(beforePlayValidationObject).forEach(([key, value]) => {
    if (
      value.mandatory &&
      (!beforePlayData[key] || beforePlayData[key] === "")
    ) {
      const transformedFieldName = transformFieldName(key);
      setBeforePlayErrorMessage((prev) => {
        return { ...prev, [key]: `${transformedFieldName} is required` };
      });
      missingFields.push(transformedFieldName);
    }
  });

  if (missingFields.length > 0) {
    toast.error(
      `Please fill in the following required fields: ${missingFields.join(
        ", "
      )}`
    );
    return false;
  }

  return true;
};

  const toTitleCase = (str) => {
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };
  
  const validateAddRewardData = () => {
    const addRewardData = data?.createGame?.game?.[gameDataName];
    if (!addRewardData || !Array.isArray(addRewardData)) {
      toast.error("Reward Data Is Missing Or Invalid");
      return false;
    }
  
    let isValid = true;
    const newErrorMessages = [];
    const missingRewards = [];
  
    addRewardData.forEach((reward, index) => {
      const validationObj = addRewardValidationObject[index];
      if (!validationObj) return;
  
      let hasMissingFields = false;
      newErrorMessages[index] = newErrorMessages[index] || {};
  
      Object.keys(validationObj).forEach((fieldKey) => {
        const validationRule = validationObj[fieldKey];
        const rewardValue = reward[fieldKey];
  
        const isNestedValue = fieldKey.includes(".");
        let actualValue = rewardValue;
  
        if (isNestedValue) {
          const [parentKey, childKey] = fieldKey.split(".");
          actualValue = reward[parentKey]?.[childKey];
        }
  
        if (validationRule.mandatory) {
          const isEmpty =
            actualValue === undefined ||
            actualValue === null ||
            actualValue === "" ||
            (typeof actualValue === "object" && Object.keys(actualValue).length === 0);
  
          if (isEmpty) {
            const fieldName = fieldKey
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(" ");
  
            newErrorMessages[index][fieldKey] = `${fieldName} is required`;
            hasMissingFields = true;
            isValid = false;
          }
        }
  
        if (validationRule.min !== undefined && actualValue < validationRule.min) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Must Be At Least ${validationRule.min}`;
          hasMissingFields = true;
          isValid = false;
        }
  
        if (validationRule.max !== undefined && actualValue > validationRule.max) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Must Not Exceed ${validationRule.max}`;
          hasMissingFields = true;
          isValid = false;
        }
  
        if (validationRule.pattern && !validationRule.pattern.test(actualValue)) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Format Is Invalid`;
          hasMissingFields = true;
          isValid = false;
        }
      });
  
      if (hasMissingFields) {
        missingRewards.push(`Reward ${index + 1}`);
      }
    });
  
    setAddRewardErrorMessage(newErrorMessages);
  
    if (!isValid) {
      toast.error(`Please Fill In All Required Fields For ${missingRewards.map(r => toTitleCase(r)).join(" And ")}`);
    }
  
    return isValid;
  };
  
  const validateAfterPlayData = () => {
    const afterPlayData = data?.createGame?.afterPlay?.[gameDataName];
    if (!afterPlayData || !Array.isArray(afterPlayData)) {
      toast.error("After Play Data Is Missing Or Invalid");
      return false;
    }
  
    let isValid = true;
    const newErrorMessages = [];
    const missingConfigs = [];
  
    afterPlayData.forEach((config, index) => {
      const validationObj = afterPlayValidationObject[0];
      if (!validationObj) return;
  
      let hasMissingFields = false;
      newErrorMessages[index] = newErrorMessages[index] || {};
  
      Object.keys(validationObj).forEach((fieldKey) => {
        const validationRule = validationObj[fieldKey];
        const fieldValue = config[fieldKey];
  
        if (validationRule.mandatory) {
          const isEmpty = 
            fieldValue === undefined || 
            fieldValue === null || 
            fieldValue === "" || 
            (typeof fieldValue === "object" && Object.keys(fieldValue).length === 0);
  
          if (isEmpty) {
            const fieldName = fieldKey
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(" ");
  
            newErrorMessages[index][fieldKey] = `${fieldName} is required`;
            hasMissingFields = true;
            isValid = false;
          }
        }
  
        if (validationRule.min !== undefined && fieldValue < validationRule.min) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Must Be At Least ${validationRule.min}`;
          hasMissingFields = true;
          isValid = false;
        }
  
        if (validationRule.max !== undefined && fieldValue > validationRule.max) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Must Not Exceed ${validationRule.max}`;
          hasMissingFields = true;
          isValid = false;
        }
  
        if (validationRule.pattern && !validationRule.pattern.test(fieldValue)) {
          const fieldName = toTitleCase(fieldKey.replace(/_/g, ' '));
          newErrorMessages[index][fieldKey] = `${fieldName} Format Is Invalid`;
          hasMissingFields = true;
          isValid = false;
        }
      });
  
      if (hasMissingFields) {
        missingConfigs.push(`Reward ${index + 1}`);
      }
    });
  
    setAfterPlayErrorMessage(newErrorMessages);
  
    if (!isValid) {
      toast.error(`Please Fill In All Required Fields For ${missingConfigs.map(r => toTitleCase(r)).join(" And ")}`);
    }
  
    return isValid;
  };

  const handleNext = () => {
    if (activeStep < steps.length-1  ) {
      if (!data?.createGame?.gameTypeName) {
        toast.error("Please select a game to proceed.");
        return;
      }

      // Add validation for Add Reward step
      if (activeStep === 1) {
        if (!validateAddRewardData()) {
          return;
        }
      }

      // Validation for Before Play step
      if (activeStep === 2) {
        if (!validateBeforePlayData()) {
          return;
        }
      }

      // Add validation for After Play step
      if (activeStep === 3) {
        if (!validateAfterPlayData()) {
          return;
        }
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
        // Add validation for After Play step
        if (activeStep === 3) {
          if (!validateAfterPlayData()) {
            return;
          }
        }
      if (pathname.includes("edit-offers")) {
        navigate(`/offers/edit-offers/preview/${id}`);
      } else {
        navigate("/offers/create-offers/preview");
      }
    }
  };
  
  console.log(data, "offer-data");
  const handleBack = () => {
    if (activeStep === 0) {
      // navigate("/offers/create-offers/set-qualifying-criteria");
      if (pathname.includes("edit-offers")) {
        navigate(`/offers/edit-offers/set-qualifying-criteria/${id}`);
      } else {
        navigate("/offers/create-offers/set-qualifying-criteria");
      }
    } else if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    dispatch(getAllRewardFunction());
    dispatch(getRewardTypesFunction(1, 5));
    dispatch(getAllGameTypes(1, 5));
  }, [dispatch]);

  useEffect(() => {
    if (pathname.includes("preview")) {
      setActiveStep(1);
    } else if (state?.activeStep) {
      setActiveStep(state?.activeStep);
    }
  }, []);

  return (
    <Box>
      <ToastContainer />
      <Box sx={{ display: showEdit ? "block" : "none" }}>
        <Box
          sx={{
            display: showEdit ? "flex" : "none",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E4E4F0",
            background: "#FFFFFF",
            height: !showEdit ? "calc(100vh - 120px)" : "none",
            // my: "16px",
            mx: "20px",
            py: "8px",
            px: "20px",
            borderRadius: "8px 8px 0px 0px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Lato",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              color: "#0B1B32",
            }}
          >
            Create Game
            <span style={{ color: "#858D98", fontSize: "14px" }}>
              ({activeStep + 1}/{steps?.length})
            </span>
          </Typography>
          <Button
            sx={{
              display: disableEditButton ? "none" : "flex",
              gap: "6px",
              // mr: "10px",
              px: "10px",
              zIndex: "10",
            }}
            onClick={() => {
              if (pathname.includes("edit-offers")) {
                navigate(`/offers/edit-offers/create-game/${id}`, {
                  state: {
                    activeStep,
                  },
                });
              } else {
                navigate("/offers/create-offers/create-game", {
                  state: {
                    activeStep,
                  },
                });
              }
            }}
          >
            <img
              style={{ height: "16px", width: "16px" }}
              src={editLogo}
              alt=""
            />
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
            >
              Edit
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "calc(100vh - 110px)",
          // minHeight:!showEdit? "calc(100vh - 110px)":"fit-content",
          margin: showEdit ? "0px 20px 20px 20px" : "20px",
          padding: "0px 20px 20px 20px",
          background: "#FFFFFF",
          borderRadius: showEdit ? "0px 0px 8px 8px" : "8px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            textAlign: "left",
            my: "20px",
            color: "#0B1B32",
            display: showEdit ? "none" : "block",
          }}
        >
          Create Game{" "}
          <span style={{ color: "#858D98", fontSize: "14px" }}>
            ({activeStep + 1}/{steps?.length})
          </span>
        </Typography>
        <Box
          sx={{
            display: showEdit ? "flex" : "none",
            justifyContent: "flex-start",
            borderBottom: "1px solid #E4E4F0",
          }}
        >
          <Button
            sx={{
              borderBottom:
                activeStep === 1
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(1)}
          >
            Add Reward
          </Button>
          <Button
            sx={{
              borderBottom:
                activeStep === 2
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(2)}
          >
            Before Play
          </Button>
          <Button
            sx={{
              borderBottom:
                activeStep === 3
                  ? "2px solid #0C51A1"
                  : "2px solid transparent",
              padding: "10px",
              borderRadius: "0px",
              fontFamily: "Lato",
              fontSize: "14px",
              fontWeight: "700",
              lineHeight: "16.8px",
              color: activeStep === 1 ? "#003D86" : "#858D98",
              textTransform: "capitalize",
            }}
            onClick={() => setActiveStep(3)}
          >
            After Play
          </Button>
        </Box>
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box
            sx={{
              width: activeStep <= 2 ? "100%" : "65%",
              // overflow: "auto",
              mt: showEdit ? "20px" : "0px",
            }}
          >
            <Box sx={{ display: showEdit ? "none" : "block" }}>
              <HorizontalLinearStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleBack={handleBack}
                handleNext={handleNext}
                steps={steps}
                showEdit={showEdit}
              />
            </Box>
            {activeStep === 0 && <AllGames />}
            {activeStep === 1 && (
              <GameType
                showEdit={showEdit}
                validationObject={addRewardValidationObject}
                errorMessageObject={addRewardErrorMessage}
              />
            )}
            {activeStep === 2 && (
              <BeforePlayPage
                showEdit={showEdit}
                validationObject={beforePlayValidationObject}
                errorMessageObject={beforePlayErrorMessage}
              />
            )}
            {activeStep === 3 && (
              <AfterPlayPage
                openRewardIndex={openRewardIndex}
                setOpenRewardIndex={setOpenRewardIndex}
                showEdit={showEdit}
                validationObject={afterPlayValidationObject}
                errorMessageObject={afterPlayErrorMessage}
                disableSaveButton={showEdit ? true : false}
              />
            )}
          </Box>
          {activeStep == 3 && (
            <Box
              sx={{
                width: "33%",
                background: "#E8F0F9",
                minHeight: "calc(100vh - 100px)",
                borderRadius: "0px 6px 0px 0px",
                position: "absolute",
                top: "0px",
                right: "0px",
                display: "flex", // Added display: flex
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column", // Changed to column layout
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1D1F2C",
                    mb: "20px",
                  }}
                >
                  Preview
                </Typography>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <Box>
                    <img
                      src={previous_image}
                      alt="previous_image"
                      style={{
                        cursor: "pointer",
                        height: "32px",
                        width: "32px",
                      }}
                      onClick={() => {
                        if (previewIndex === 0) {
                          setPreviewIndex(1);
                        } else {
                          setPreviewIndex(0);
                        }
                      }}
                    />
                  </Box>

                  <Box>
                    {previewIndex === 0 ? (
                      <AndroidMockup
                        screenWidth={220}
                        frameColor="#0D0D0F"
                        statusbarColor="#0D0D0F"
                      >
                        <Box
                          sx={{
                            height: "100%",
                            // backgroundColor: "#212121CC",
                            backgroundImage: `url(${transparent_background})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            // opacity:"80%",
                            width: "100%",
                          }}
                        >
                          <Box mt="120px">
                            <Box sx={{ position: "relative" }}>
                              <img
                                style={{ width: "160px", height: "160px" }}
                                src={
                                  // data?.createGame?.afterPlay?.[gameDataName]?.[
                                  //   openRewardIndex ? openRewardIndex : 0
                                  // ]?.logo_image
                                  data?.createGame?.afterPlay?.[gameDataName]?.[
                                    openRewardIndex ? openRewardIndex : 0
                                  ]?.scratch_card_image
                                }
                                alt=""
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "60%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  src={
                                    data?.createGame?.afterPlay?.[
                                      gameDataName
                                    ]?.[openRewardIndex ? openRewardIndex : 0]
                                      ?.logo_image
                                  }
                                  alt=""
                                />
                                <Typography
                                  sx={{
                                    color:
                                      data?.createGame?.afterPlay?.[
                                        gameDataName
                                      ]?.[openRewardIndex ? openRewardIndex : 0]
                                        ?.title_colour,
                                    fontFamily: "Proxima Nova A",
                                    fontSize: "6.69px",
                                    fontWeight: "600",
                                    lineHeight: "8.92px",
                                    letterSpacing: "1.12px",
                                    height: "8.92px",
                                  }}
                                >
                                  {
                                    data?.createGame?.afterPlay?.[
                                      gameDataName
                                    ]?.[openRewardIndex ? openRewardIndex : 0]
                                      ?.title
                                  }
                                </Typography>
                                <Typography
                                  sx={{
                                    color:
                                      data?.createGame?.afterPlay?.[
                                        gameDataName
                                      ]?.[openRewardIndex ? openRewardIndex : 0]
                                        ?.reward_value_color,
                                    fontFamily: "Proxima Nova A",
                                    fontSize: "15.62px",
                                    fontWeight: "700",
                                    lineHeight: "18.96px",
                                    mt: "7px",
                                  }}
                                >
                                  100 Neucoins
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                height: "27px",
                                marginTop: "15px",
                                paddingX: "45px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "8.97px",
                                  fontWeight: "400",
                                  lineHeight: "13.45px",
                                  color: "#FFFFFF",
                                  textAlign: "center",
                                }}
                              >
                                Scratch the card to win an assured reward
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              margin: "9px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              gap: "5px",
                              mt: "60px",
                              marginLeft: "12px",
                              // justifyContent:"center",
                            }}
                          >
                            <Box
                              sx={{
                                border: "0.5px solid #B2B2B2",
                                width: "75px",
                              }}
                            ></Box>
                            <Typography
                              sx={{
                                fontFamily: "Proxima Nova A",
                                fontSize: "6.73px",
                                lineHeight: "8.97px",
                                color: "#E5E5E5",
                              }}
                            >
                              Earned via
                            </Typography>
                            <Box
                              sx={{
                                border: "0.5px solid #B2B2B2",
                                width: "75px",
                              }}
                            ></Box>
                          </Box>

                          <Box
                            sx={{
                              margin: "5px 9px",
                              height: "35px",
                              backgroundColor: "#FFFFFF1F ",
                              borderRadius: "7px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Proxima Nova A",
                                fontSize: "8.97px",
                                fontWeight: "600",
                                lineHeight: "13.45px",
                                color: "#FFFFFF",
                                textAlign: "center",
                              }}
                            >
                              Air India Express Flight Booking
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Proxima Nova A",
                                fontSize: 6.73,
                                fontWeight: "400",
                                lineHeight: "8.97px",
                                textAlign: "center",

                                color: "#E5E5E5",
                              }}
                            >
                              11 Oct 2023 · Tata Pay Later
                            </Typography>
                          </Box>
                        </Box>
                      </AndroidMockup>
                    ) : (
                      <AndroidMockup
                        screenWidth={220}
                        frameColor="#0D0D0F"
                        statusbarColor="#0D0D0F"
                      >
                        <Box
                          sx={{
                            height: "100%",
                            backgroundColor: "#EBF0FF7A",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              height: "46px",
                              backgroundColor: "#FDFDFD",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              gap: "10px",
                            }}
                          >
                            <IoIosArrowBack
                              style={{
                                marginLeft: "10px",
                                fontSize: "18px",
                                color: "#1A1A1A",
                              }}
                            />
                            <Typography
                              sx={{
                                fontFamily: "Proxima Nova A",
                                fontSize: "14px",
                                fontWeight: "700",
                                lineHeight: "20px",
                                color: "#1A1A1A",
                                textAlign: "start",
                              }}
                            >
                              Reward Details
                            </Typography>
                          </Box>
                          <Box mt="16px">
                            <Box sx={{ position: "relative" }}>
                              <img
                                style={{ width: "160px", height: "160px" }}
                                src={
                                  // data?.createGame?.afterPlay?.[gameDataName]?.[
                                  //   openRewardIndex ? openRewardIndex : 0
                                  // ]?.logo_image
                                  data?.createGame?.afterPlay?.[gameDataName]?.[
                                    openRewardIndex ? openRewardIndex : 0
                                  ]?.scratch_card_image
                                }
                                alt=""
                              />
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "60%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <img
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                  }}
                                  src={
                                    data?.createGame?.afterPlay?.[
                                      gameDataName
                                    ]?.[openRewardIndex ? openRewardIndex : 0]
                                      ?.logo_image
                                  }
                                  alt=""
                                />
                                <Typography
                                  sx={{
                                    color:
                                      data?.createGame?.afterPlay?.[
                                        gameDataName
                                      ]?.[openRewardIndex ? openRewardIndex : 0]
                                        ?.title_colour,
                                    fontFamily: "Proxima Nova A",
                                    fontSize: "6.69px",
                                    fontWeight: "600",
                                    lineHeight: "8.92px",
                                    letterSpacing: "1.12px",
                                    height: "8.92px",
                                  }}
                                >
                                  {
                                    data?.createGame?.afterPlay?.[
                                      gameDataName
                                    ]?.[openRewardIndex ? openRewardIndex : 0]
                                      ?.title
                                  }
                                </Typography>
                                <Typography
                                  sx={{
                                    color:
                                      data?.createGame?.afterPlay?.[
                                        gameDataName
                                      ]?.[openRewardIndex ? openRewardIndex : 0]
                                        ?.reward_value_color,
                                    fontFamily: "Proxima Nova A",
                                    fontSize: "15.62px",
                                    fontWeight: "700",
                                    lineHeight: "18.96px",
                                    mt: "7px",
                                  }}
                                >
                                  100 Neucoins
                                </Typography>
                              </Box>
                            </Box>

                            <Box
                              sx={{
                                height: "27px",
                                marginTop: "10px",
                                paddingX: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  lineHeight: "20px",
                                  color: "#1A1A1A",
                                  textAlign: "center",
                                  height: "20px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 1,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {
                                  data?.createGame?.afterPlay?.[gameDataName]?.[
                                    openRewardIndex || 0
                                  ]?.after_play_details_title
                                }
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  color: "#4D4D4D",
                                  textAlign: "center",
                                  mt: "5px",
                                  height: "36px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {
                                  data?.createGame?.afterPlay?.[gameDataName]?.[
                                    openRewardIndex || 0
                                  ]?.after_play_details_description
                                }
                              </Typography>
                              <Button
                                sx={{
                                  height: "32px",
                                  width: "112px",
                                  borderRadius: "8px",
                                  backgroundColor: "white",
                                  border:
                                    "1px solid var(--opacity-outline-24, #78787D3D)",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  marginTop: "10px",
                                  "&:hover": {
                                    backgroundColor: "white",
                                  },
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "10px",
                                  fontWeight: "600",
                                  lineHeight: "20px",
                                  color: "#1A1A1A",
                                  padding: "0px",
                                }}
                              >
                                Start Shopping
                              </Button>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              height: "62px",
                              // width: "100%",
                              backgroundColor: "#FDFDFD",
                              borderRadius: "20px 20px 0px 0px",
                              marginTop: "120px",
                              padding: "16px",
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#8800EC",
                                borderRadius: "8px",
                                width: "100%",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Proxima Nova A",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  lineHeight: "18px",
                                  color: "#FFFFFF",
                                }}
                              >
                                Go back to My Rewards
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </AndroidMockup>
                    )}
                  </Box>

                  <Box>
                    <img
                      src={previous_image}
                      alt="previous_image"
                      style={{
                        cursor: "pointer",
                        height: "32px",
                        width: "32px",
                        rotate: "180deg",
                      }}
                      onClick={() => {
                        if (previewIndex === 0) {
                          setPreviewIndex(1);
                        } else {
                          setPreviewIndex(0);
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1D1F2C",
                    mt: "20px",
                  }}
                >{`(${previewIndex + 1}/2)`}</Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: showEdit ? "none" : "flex",
            justifyContent: "flex-start",
            gap: "16px",
            padding: "20px 0px 0px 0px",
            marginTop: "auto",
          }}
        >
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
            }}
            onClick={handleBack}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
            >
              Previous
            </Typography>
          </Button>
          <Button
            sx={{
              width: "136px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
            }}
            onClick={handleNext}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Save & Next
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
