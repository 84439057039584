import axios from "axios";
import {
  allDataSubmitted,
  editOfferFail,
  editOfferSuccess,
  errorSubmitOffer,
  getCategory,
  getCohortsData,
  getCurrency,
  getGames,
  getGameType,
  getInitiative,
  getMerchant,
  getPreviouslyCreateRewards,
  getProduct,
  getRewardTypes,
  getRuleSet,
  getSubCategory,
} from "./actionType";

const BaseUrl = process.env.REACT_APP_API_BASE_URL;

const getToken = () => {
  const data = localStorage.getItem("Auth");
  const AuthDetails = data ? JSON.parse(data) : {};
  return AuthDetails?.accessToken || "";
};

export const getCategoryFunction = (token) => (dispatch) => {
  let params = { table: "category" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCategory, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getSubCategoryFunction = (ID) => (dispatch) => {
  const token = getToken();
  console.log(token, "token");
  const categoryIdsString = JSON.stringify(ID);
  let params = { table: "subcategory", category_id: categoryIdsString };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getSubCategory, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const getProductFunction = (ID) => (dispatch) => {
//   const categoryIdsString = JSON.stringify(ID);
//   const token = getToken();
//   let params = { table: "product", subcategory_id: categoryIdsString };
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/get`, { params, ...config })
//     .then((res) => {
//       dispatch({ type: getProduct, payload: res.data });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// export const getProductFunction = () => (dispatch) => {
 
//   const token = getToken();
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/product-list`, config)
//     .then((res) => {
//       dispatch({ type: getProduct, payload: res.data });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const getProductFunction = () => (dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/products`, {}, config) // Passing an empty object for the body
    .then((res) => {
      dispatch({ type: getProduct, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};


export const getMerchantFunction = () => (dispatch) => {
  const token = getToken();
  let params = { table: "merchant" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getMerchant, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getRuleSetFunction = () => (dispatch) => {
  let params = { table: "rule_set" };
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getRuleSet, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCohortsSetFunction = () => (dispatch) => {
  let params = { table: "collections" };
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCohortsData, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const getCohortsSetFunction = () => (dispatch) => {
//   // let params = { table: "rule_set" };
//   const token = getToken();
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   axios
//     .get(`${BaseUrl}/api/collections`, { ...config })
//     .then((res) => {
//       dispatch({ type: getCohortsData, payload: res.data.data });
//       // console.log(res.data.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

export const getInitiativeFunction = () => (dispatch) => {
  const token = getToken();
  let params = { table: "initiative" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getInitiative, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getGamesFunction = (token) => (dispatch) => {
  let params = { table: "games" };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getGames, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCurrencyFunction = (token) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let params = { table: "currency" };
  axios
    .get(`${BaseUrl}/api/get`, { params, ...config })
    .then((res) => {
      dispatch({ type: getCurrency, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleSubmitAllData = (data, setLoading) => (dispatch) => {
  const token = getToken();
  setLoading(true);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/create-offer`, data, config)
    .then((res) => {
      dispatch({ type: allDataSubmitted, payload: res.data });
      console.log(res.data, "all");
      setLoading(false);
    })
    .catch((error) => {
      dispatch({
        type: errorSubmitOffer,
        payload: error?.response?.data?.message,
      });
      console.log(error);
      setLoading(false);
    });
};

export const handleEditData = (editedData, id) => (dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .post(`${BaseUrl}/api/admin/offer/${id}`, editedData, config)
    .then((res) => {
      dispatch({ type: editOfferSuccess, payload: res.data });
      console.log(res.data, "edited");
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: editOfferFail,
        payload: error?.response?.data?.message,
      });
    });

  // dispatch({ type: editOfferSuccess,});
};

export const getAllGameTypes =
  (pageNumber = 1, pageSize = 5) =>
  (dispatch) => {
    const token = getToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(
        `${BaseUrl}/api/game-types`,
        { pageNumber, pageSize },
        config
      )
      .then((res) => {
        console.log(res.data);
        dispatch({ type: getGameType, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  export const getRewardTypesFunction = (pageNumber = 1, pageSize = 5) => (dispatch) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const body = {
      pageNumber,
      pageSize
    }
    
    axios
      .post(`${BaseUrl}/api/reward-types`,body, config)
      .then((res) => {
        dispatch({ type: getRewardTypes, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  export const getAllRewardFunction = () => (dispatch) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(`${BaseUrl}/api/reward`,null,config )
      .then((res) => {
        dispatch({ type: getPreviouslyCreateRewards, payload: res.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };