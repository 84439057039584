export const reward_type = [
    {
        "id": 8,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a30c64_Cash back icon.png",
        "name": "CASHBACK",
        "code": "cashback",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:34:34",
        "last_modified_at": "2025-01-16 09:45:37"
    },
    {
        "id": 9,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5adfcb_voucher_icon.png",
        "name": "Promotions",
        "code": "voucher",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Offer Id",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "oe_offer_id"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Reward Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color"
                    },
                    {
                        "label": "Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Currency logo",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Merchant Logo*",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            },
                            {
                                "label": "Offer T&C",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:35:03",
        "last_modified_at": "2025-01-28 04:39:34"
    },
    {
        "id": 10,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png",
        "name": "NeuCoins",
        "code": "neu_coin",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Reward Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color"
                    },
                    {
                        "label": "Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:36:28",
        "last_modified_at": "2025-01-16 11:52:17"
    },
    {
        "id": 11,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
        "name": "Digital Gold",
        "code": "digital_gold",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Reward Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color"
                    },
                    {
                        "label": "Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color"
                    },
                    {
                        "label": "Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:36:40",
        "last_modified_at": "2025-01-16 11:50:59"
    },
    {
        "id": 12,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png",
        "name": "BETTER LUCK NEXT TIME",
        "code": "bl_next_time",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-08-02 04:11:01",
        "last_modified_at": "2025-01-16 09:48:07"
    },
    {
        "id": 13,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png",
        "name": "Promo Neucoins",
        "code": "promo_neu_coin",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "NeuCoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Promo Identifier",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "promo_id"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            "6": {
                "after_play": [
                    {
                        "label": "Logo Image 1",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_1"
                    },
                    {
                        "label": "Logo Image 2",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_2"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "after_play_details_title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Spoke Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "eg : Get 100 Neucoins",
                        "backend_label": "spoke_title"
                    },
                    {
                        "label": "Spoke Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_spoke_title_color"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_merchant_logo"
                    }
                ]
            },
            "9": {
                "after_play": [
                    {
                        "label": "Logo Image 1",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_1"
                    },
                    {
                        "label": "Logo Image 2",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image_2"
                    },
                    {
                        "label": "Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Subtitle",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image"
                    }
                ]
            }
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-11-05 11:33:55",
        "last_modified_at": "2025-01-28 04:54:57"
    }
]

export const rewards = [
    {
        "id": 8,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a30c64_Cash back icon.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "CASHBACK",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "currency_id": 12,
        "reward_type_code": "cashback",
        "created_at": "2024-07-31 07:34:34",
        "last_modified_at": "2024-11-21 11:57:35"
    },
    {
        "id": 9,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5adfcb_voucher_icon.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "VOUCHER",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "currency_id": 11,
        "reward_type_code": "voucher",
        "created_at": "2024-07-31 07:35:03",
        "last_modified_at": "2024-11-21 11:59:00"
    },
    {
        "id": 10,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f1e32a_neucoin_icon.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "Neucoins",
        "code": "neu_coin",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            6:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "mandatory": true,
                        "input_type": "dropdown",
                        options:[
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "backend_label": "before_play_reward",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Reward Color",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Title Color",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Logo",
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo",
                        "options":[
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            }
                        ]
                    },
                ],
            },
            9:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image",
                        "options":[
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            }
                        ]
                    },
                ],
            },
          
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "reward_type_code": "neu_coin",
        "created_at": "2024-07-31 07:36:28",
        "last_modified_at": "2024-11-21 11:50:37"
    },
    {
        "id": 11,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "Digital Gold",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "label": "Cashback Type",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "label": "Fixed",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Min. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "type": "number",
                                "label": "Avg. Value",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "type": "number",
                                "label": "Std. Deviation",
                                "style": {
                                    "width": "22%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "Percentage",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "type": "number",
                                "label": "Percentage",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "type": "number",
                                "label": "Max. Value",
                                "style": {
                                    "width": "48%"
                                },
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            6:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "mandatory": true,
                        "input_type": "dropdown",
                        options:[
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "backend_label": "before_play_reward",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Reward Color",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_reward_color",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Title Color",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_title_color",
                        style: {width: "48%"}
                    },
                    {
                        "label": "Logo",
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_logo",
                        "options":[
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            }
                        ]
                    },
                ],
            },
            9:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image",
                        "options":[
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            }
                        ]
                    },
                ],
            },
          
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "currency_id": 1,
        "reward_type_code": "digital_gold",
        "created_at": "2024-07-31 07:36:40",
        "last_modified_at": "2024-11-21 12:01:29"
    },
    {
        "id": 12,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/After.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "BETTER LUCK NEXT TIME",
        "reward_details": [
            {
                "label": "Select Reward Type",
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "label": "Select Cashback Type",
                "options": [
                    {
                        "label": "Fixed Cashback",
                        "value": "FIXED_CASHBACK",
                        "fields": [
                            {
                                "label": "Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "reward_value"
                            }
                        ]
                    },
                    {
                        "label": "Dynamic Cashback",
                        "value": "DYNAMIC_CASHBACK",
                        "fields": [
                            {
                                "label": "Min Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "min"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max"
                            },
                            {
                                "label": "Avg Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "mean"
                            },
                            {
                                "label": "Std Deviation",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "standard_deviation"
                            }
                        ]
                    },
                    {
                        "label": "% Cashback",
                        "value": "PERCENTAGE_CASHBACK",
                        "fields": [
                            {
                                "label": "Percentage",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "percentage"
                            },
                            {
                                "label": "Max Value",
                                "mandatory": true,
                                "input_type": "input_box",
                                "backend_label": "max_value"
                            }
                        ]
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "cashback_type"
            },
            {
                "label": "Reward Value",
                "mandatory": true,
                "input_type": "array",
                "backend_label": "value"
            },
            {
                "label": "Reward Title",
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "title"
            },
            {
                "label": "Choose A Reward Icon",
                "options": [
                    "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww",
                    "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                    "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                ],
                "mandatory": true,
                "input_type": "image_upload",
                "backend_label": "reward_icon"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "currency_id": null,
        "reward_type_code": "bl_next_time",
        "created_at": "2024-08-02 04:11:01",
        "last_modified_at": "2024-11-21 12:02:06"
    },
    {
        "id": 13,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/d5603e_digital_gold_icon.png?sv=2025-01-05&st=2024-11-25T11%3A52%3A54Z&se=2024-11-26T11%3A52%3A54Z&sr=c&sp=r&sig=%2BSWeKj2L%2B%2BJ%2F9kDntdXrxXSuMAQobXO%2B0y3MMfR0PC4%3D",
        "name": "PROMO NEUCOINS",
        "reward_details": [
            {
                "label": "Reward",
                "style": {
                    "width": "31.3%"
                },
                "options": [
                    {
                        "id": "better_luck_next_time",
                        "name": "Better Luck Next Time"
                    },
                    {
                        "id": "voucher",
                        "name": "Voucher"
                    },
                    {
                        "id": "neu_coin",
                        "name": "Neucoins"
                    },
                    {
                        "id": "digital_gold",
                        "name": "Digital Gold"
                    },
                    {
                        "id": "cashback",
                        "name": "Cashback"
                    },
                    {
                        "id": "promo_neu_coin",
                        "name": "PROMO NEUCOINS"
                    }
                ],
                "mandatory": true,
                "input_type": "dropdown",
                "backend_label": "reward_type"
            },
            {
                "type": "number",
                "label": "Win Probability",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "win_probability"
            },
            {
                "type": "string",
                "label": "Program ID",
                "style": {
                    "width": "31.3%"
                },
                "mandatory": true,
                "input_type": "input_box",
                "backend_label": "program_id"
            },
            {
                "mandatory": false,
                "input_type": "reward_limit",
                "limitOptions": {
                    "limitTypeOptions": [
                        {
                            "id": "reward_value",
                            "name": "Value"
                        },
                        {
                            "id": "reward_count",
                            "name": "No. of Redemptions"
                        }
                    ],
                    "limitLevelOptions": [
                        {
                            "id": "product",
                            "name": "Product"
                        },
                        {
                            "id": "user",
                            "name": "User"
                        }
                    ]
                },
                "backend_label": "reward_limit"
            }
        ],
        "design_details": {
            6:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Reward",
                        "style": {
                            "width": "48%"
                        },
                        "options": [
                            {
                                "id": "better_luck_next_time",
                                "name": "Better Luck Next Time"
                            },
                            {
                                "id": "voucher",
                                "name": "Voucher"
                            },
                            {
                                "id": "neu_coin",
                                "name": "Neucoins"
                            },
                            {
                                "id": "digital_gold",
                                "name": "Digital Gold"
                            },
                            {
                                "id": "cashback",
                                "name": "Cashback"
                            },
                            {
                                "id": "promo_neu_coin",
                                "name": "PROMO NEUCOINS"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "dropdown",
                        "backend_label": "before_play_reward"
                    },
                    {
                        "label": "Spoke Title",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "eg : Get 100 Neucoins",
                        "backend_label": "spoke_title"
                    },
                    {
                        "label": "Spoke Title Color",
                        "style": {
                            "width": "48%"
                        },
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "before_play_spoke_title_color"
                    },
                    {
                        "label": "Merchant Logo",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "before_play_merchant_logo"
                    }
                ]
            },
            9:{
                "after_play": [
                    {
                        "label": "Logo Image",
                        "options": [
                            {
                                "id": 1579,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/630b80_neu1.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1580,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/c8f563_neu2.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1581,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/010a11_neu3.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "logo_image"
                    },
                    {
                        "label": "Title",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "1000 Neucoins",
                        "backend_label": "title"
                    },
                    {
                        "label": "Subtitle",
                        "mandatory": true,
                        "input_type": "input_box",
                        "placeholder": "on your next purchase",
                        "backend_label": "subtitle"
                    },
                    {
                        "label": "Title Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "title_colour"
                    },
                    {
                        "label": "Reward Value Colour",
                        "style": {
                            "width": "48%"
                        },
                        "default": "#000000",
                        "mandatory": true,
                        "input_type": "color_picker",
                        "backend_label": "reward_value_color"
                    },
                    {
                        "label": "Scratch Card Image",
                        "options": [
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2024-11-04&st=2024-12-09T05%3A06%3A27Z&se=2024-12-10T05%3A06%3A27Z&sr=c&sp=r&sig=s6D2vKzA6PL2XbWmktx5%2FYJY7Remiqirc42iY%2B9ol%2Fo%3D"
                            }
                        ],
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "scratch_card_image"
                    },
                    {
                        "label": "After Play Details",
                        "fields": [
                            {
                                "label": "Title",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : Congratulations!",
                                "backend_label": "title",
                                "max_characters": 50
                            },
                            {
                                "label": "Description",
                                "mandatory": true,
                                "input_type": "input_box",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "description",
                                "max_characters": 100
                            },
                            {
                                "label": "How to Redeem",
                                "mandatory": true,
                                "input_type": "text_area",
                                "placeholder": "e.g : You have won 100 Neucoins",
                                "backend_label": "how_to_redeem",
                                "max_characters": 200
                            }
                        ],
                        "mandatory": true,
                        "input_type": "json",
                        "backend_label": "after_play_details"
                    }
                ],
                "before_play": [
                    {
                        "label": "Cover Image",
                        "mandatory": true,
                        "input_type": "image_upload",
                        "backend_label": "cover_image",
                        "options":[
                            {
                                "id": 1582,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1583,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1584,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1585,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1586,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1587,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            },
                            {
                                "id": 1588,
                                "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png?sv=2025-01-05&st=2024-11-26T11%3A53%3A37Z&se=2024-11-27T11%3A53%3A37Z&sr=c&sp=r&sig=E6GOgSGP%2BjrUitV99GtbPnJ%2FHbsqrfTRRRbP0BW5KSg%3D"
                            }
                        ]
                    },
                ],
            },
          
        },
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "currency_id": 13,
        "reward_type_code": "promo_neu_coin",
        "created_at": "2024-11-05 11:33:55",
        "last_modified_at": "2024-11-21 13:15:07"
    }
]

export const game_type = [
    {
        "id": 9,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fe0c85_Scrach_CARD_ICON.png",
        "name": "Scratch Card",
        "game_type_code": "scratch_card",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "description": "This color defines the background behind the scratch card",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch card “Locked State” Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scartch_card_locked_image"
                },
                {
                    "label": "Scratch card “Unlocked State” Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scartch_card_unlocked_image"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-08-01 12:14:02",
        "last_modified_at": "2025-01-28 04:50:47"
    },
    {
        "id": 6,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ae7e6f_STW thumbnail.png",
        "name": "Spin The Wheel",
        "game_type_code": "spin_the_wheel",
        "design_fields": {
            "quiz": [
                {
                    "label": "Question Type",
                    "options": [
                        {
                            "id": "single",
                            "name": "Single Choice"
                        },
                        {
                            "id": "multiple",
                            "name": "Multiple Choice"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "question",
                    "placeholder": "eg : Holi is celebrated as a festival of? ",
                    "backend_label": "question"
                }
            ],
            "after_play": [
                {
                    "label": "Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Congratulations!",
                    "backend_label": "title"
                },
                {
                    "label": "Congratulations Image",
                    "options": [
                        {
                            "id": 1582,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f30ad8_Gratify v2.0 Neucoins.png"
                        },
                        {
                            "id": 1583,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/e2bb97_Neucoins.png"
                        },
                        {
                            "id": 1584,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/157659_Neucoins (1).png"
                        },
                        {
                            "id": 1585,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/595e0a_Neucoins (2).png"
                        },
                        {
                            "id": 1586,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47e718_Neucoins (3).png"
                        },
                        {
                            "id": 1587,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/baeab7_Neucoins (4).png"
                        },
                        {
                            "id": 1588,
                            "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fb9e90_Neucoins (5).png"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "congratulation_image"
                }
            ],
            "before_play": [
                {
                    "label": "No. of Spins*",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": "3",
                            "name": "Three"
                        },
                        {
                            "id": "4",
                            "name": "Four"
                        },
                        {
                            "id": "5",
                            "name": "Five"
                        },
                        {
                            "id": "6",
                            "name": "Six"
                        },
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "reward_type"
                },
                {
                    "label": "Game Instruction",
                    "style": {
                        "width": "100%"
                    },
                    "mandatory": true,
                    "input_type": "text_area",
                    "backend_label": "game_instruction"
                },
                {
                    "label": "Terms & Conditions",
                    "style": {
                        "width": "100%"
                    },
                    "mandatory": true,
                    "input_type": "text_area",
                    "backend_label": "terms_and_conditions"
                },
              
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:28:05",
        "last_modified_at": "2024-12-23 11:54:03"
    },
    {
        "id": 8,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/9fa732_STW_ICON_COL.png",
        "name": "Trivia",
        "game_type_code": "trivia",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "note": "This color defines the background behind the scratch card",
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch Card Image",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "default": "#000000",
                            "options": [
                                {
                                    "id": 1543,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/b525e2_Gratify v2.0 Rectangle.png"
                                },
                                {
                                    "id": 1544,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fd466b_Gratify v2.0 Rectangle 161123625.png"
                                },
                                {
                                    "id": 1545,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/849da6_Gratify v2.0 Rectangle (1).png"
                                },
                                {
                                    "id": 1546,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ffb638_Gratify v2.0 Rectangle (2).png"
                                },
                                {
                                    "id": 1547,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/12351e_Gratify v2.0 Rectangle (3).png"
                                },
                                {
                                    "id": 1548,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/7e8764_Gratify v2.0 Rectangle (4).png"
                                },
                                {
                                    "id": 1549,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/492600_Gratify v2.0 Rectangle (5).png"
                                },
                                {
                                    "id": 1550,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ff2d75_Gratify v2.0 Rectangle (6).png"
                                },
                                {
                                    "id": 1551,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/54216d_Gratify v2.0 Rectangle (7).png"
                                },
                                {
                                    "id": 1552,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4b3749_Gratify v2.0 Rectangle 161123628.png"
                                },
                                {
                                    "id": 1553,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2f10a5_Gratify v2.0 Rectangle 161123629.png"
                                },
                                {
                                    "id": 1554,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/71bbb1_Gratify v2.0 Rectangle 161123631.png"
                                },
                                {
                                    "id": 1555,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/84b867_Gratify v2.0 Rectangle 161123632.png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                {
                                    "id": 1556,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/25ec3d_responsive-media.png"
                                },
                                {
                                    "id": 1557,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a40d18_Gratify v2.0 Frame.png"
                                },
                                {
                                    "id": 1558,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47377c_Gratify v2.0 Frame (1).png"
                                },
                                {
                                    "id": 1559,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/13a5cd_Gratify v2.0 Frame (2).png"
                                },
                                {
                                    "id": 1560,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/dc39d9_Gratify v2.0 Frame (3).png"
                                },
                                {
                                    "id": 1561,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f20243_Gratify v2.0 Frame (4).png"
                                },
                                {
                                    "id": 1562,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5e5966_Gratify v2.0 Frame (5).png"
                                },
                                {
                                    "id": 1563,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/663e74_Gratify v2.0 Frame (6).png"
                                },
                                {
                                    "id": 1564,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5a0408_Gratify v2.0 Frame (7).png"
                                },
                                {
                                    "id": 1565,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/28df7c_Gratify v2.0 Frame (8).png"
                                },
                                {
                                    "id": 1566,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2e8f97_Gratify v2.0 Frame (9).png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scratch_card"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": [
            {
                "label": "Download Sample",
                "value": "https://www.example.com/voucher_csv.csv",
                "mandatory": true
            },
            {
                "label": "Upload Voucher CSV",
                "mandatory": false,
                "backend_label": "voucher_csv"
            },
            {
                "label": "Manual Input",
                "fields": [
                    {
                        "label": "Question",
                        "mandatory": true,
                        "backend_label": "question"
                    },
                    {
                        "label": "Add Media",
                        "mandatory": false,
                        "backend_label": "uploaded_media"
                    },
                    {
                        "label": "Answer Options",
                        "fields": [
                            {
                                "label": "option1",
                                "value": "option1",
                                "is_answer": true
                            },
                            {
                                "label": "option2",
                                "value": "option2",
                                "is_answer": false
                            }
                        ],
                        "min_value": 2,
                        "backend_label": "answer_options",
                        "additionalFields": true
                    }
                ],
                "mandatory": false
            }
        ],
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:31:41",
        "last_modified_at": "2024-09-24 11:51:15"
    },
    {
        "id": 7,
        "icon": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4efd27_machine.png",
        "name": "Slot Machine",
        "game_type_code": "slot_machine",
        "design_fields": {
            "after_play": [
                {
                    "label": "Reward Title",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "title"
                },
                {
                    "label": "Title Color",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "label": "Reward Title (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "input_box",
                    "backend_label": "no_reward_title"
                },
                {
                    "label": "Title Color (Only for No Reward)",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "no_reward_title_color"
                },
                {
                    "label": "Scratch Card",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "scratch_card"
                },
                {
                    "label": "Background",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "mandatory": true,
                            "backend_label": "background_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                "https://images.unsplash.com/photo-1653489218319-0e59c55ddc68?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1694004709804-e51d0c54f57f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c2NyYXRjaCUyMGNhcmR8ZW58MHx8MHx8fDA%3D",
                                "https://plus.unsplash.com/premium_photo-1681533650874-9a0c01a7ee53?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHNjcmF0Y2glMjBjYXJkfGVufDB8fDB8fHww"
                            ],
                            "mandatory": true,
                            "backend_label": "background_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_and_color_picker",
                    "backend_label": "reward_background"
                }
            ],
            "before_play": [
                {
                    "label": "Title",
                    "style": {
                        "width": "48%"
                    },
                    "mandatory": true,
                    "input_type": "input_box",
                    "placeholder": "eg : Spin the wheel to win",
                    "backend_label": "title",
                    "max_characters": 50
                },
                {
                    "label": "Title Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "title_color"
                },
                {
                    "note": "This color defines the background behind the scratch card",
                    "label": "Background Overlay Color",
                    "style": {
                        "width": "48%"
                    },
                    "default": "#000000",
                    "mandatory": true,
                    "input_type": "color_picker",
                    "backend_label": "background_overlay_color"
                },
                {
                    "label": "Show Earned Via In Before & After Scratch",
                    "style": {
                        "width": "48%"
                    },
                    "options": [
                        {
                            "id": true,
                            "name": "Yes"
                        },
                        {
                            "id": false,
                            "name": "No"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "dropdown",
                    "backend_label": "show_earned_via"
                },
                {
                    "label": "Scratch Card Image",
                    "options": [
                        {
                            "label": "Color",
                            "value": "color",
                            "default": "#000000",
                            "options": [
                                {
                                    "id": 1543,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/b525e2_Gratify v2.0 Rectangle.png"
                                },
                                {
                                    "id": 1544,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/fd466b_Gratify v2.0 Rectangle 161123625.png"
                                },
                                {
                                    "id": 1545,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/849da6_Gratify v2.0 Rectangle (1).png"
                                },
                                {
                                    "id": 1546,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ffb638_Gratify v2.0 Rectangle (2).png"
                                },
                                {
                                    "id": 1547,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/12351e_Gratify v2.0 Rectangle (3).png"
                                },
                                {
                                    "id": 1548,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/7e8764_Gratify v2.0 Rectangle (4).png"
                                },
                                {
                                    "id": 1549,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/492600_Gratify v2.0 Rectangle (5).png"
                                },
                                {
                                    "id": 1550,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/ff2d75_Gratify v2.0 Rectangle (6).png"
                                },
                                {
                                    "id": 1551,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/54216d_Gratify v2.0 Rectangle (7).png"
                                },
                                {
                                    "id": 1552,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/4b3749_Gratify v2.0 Rectangle 161123628.png"
                                },
                                {
                                    "id": 1553,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2f10a5_Gratify v2.0 Rectangle 161123629.png"
                                },
                                {
                                    "id": 1554,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/71bbb1_Gratify v2.0 Rectangle 161123631.png"
                                },
                                {
                                    "id": 1555,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/84b867_Gratify v2.0 Rectangle 161123632.png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_color"
                        },
                        {
                            "label": "Image",
                            "value": "image",
                            "options": [
                                {
                                    "id": 1556,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/25ec3d_responsive-media.png"
                                },
                                {
                                    "id": 1557,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/a40d18_Gratify v2.0 Frame.png"
                                },
                                {
                                    "id": 1558,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/47377c_Gratify v2.0 Frame (1).png"
                                },
                                {
                                    "id": 1559,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/13a5cd_Gratify v2.0 Frame (2).png"
                                },
                                {
                                    "id": 1560,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/dc39d9_Gratify v2.0 Frame (3).png"
                                },
                                {
                                    "id": 1561,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/f20243_Gratify v2.0 Frame (4).png"
                                },
                                {
                                    "id": 1562,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5e5966_Gratify v2.0 Frame (5).png"
                                },
                                {
                                    "id": 1563,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/663e74_Gratify v2.0 Frame (6).png"
                                },
                                {
                                    "id": 1564,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/5a0408_Gratify v2.0 Frame (7).png"
                                },
                                {
                                    "id": 1565,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/28df7c_Gratify v2.0 Frame (8).png"
                                },
                                {
                                    "id": 1566,
                                    "url": "https://gratifystorage01.blob.core.windows.net/gratify-dev/2e8f97_Gratify v2.0 Frame (9).png"
                                }
                            ],
                            "mandatory": true,
                            "backend_label": "scratch_card_image"
                        }
                    ],
                    "mandatory": true,
                    "input_type": "image_upload",
                    "backend_label": "scratch_card"
                }
            ],
            "minimum_rewards": {
                "label": "Minimum",
                "value": 1,
                "mandatory": true
            }
        },
        "game_fields": {},
        "organization_id": "7e106187-c2c1-49a3-8728-6d3736694bad",
        "created_at": "2024-07-31 07:30:13",
        "last_modified_at": "2024-09-24 11:51:14"
    }
]