import React, { useRef, useState } from "react";
import OfferDetailsPage from "./OfferDetailsPage";
import SetQualifyingCriteria from "./SetQualifyingCriteria";
import CreateGamePage from "./CreateGamePage";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Modal, Typography } from "@mui/material";
import { getMakerCheckerPermission, getToken } from "../../../utils/token";
import axios from "axios";
import { clearCreateOfferData } from "../../../Redux/OfferReducer/actionType";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import linkImg from "../../../assets/linkImg.png";
import tick from "../../../assets/tick-circle.svg";
import closeBtnLogo from "../../../assets/closeBtnlogo.png";
import { toast, ToastContainer } from "react-toastify";
import link2 from "../../../assets/link-2.svg";
import CustomConfirmationDialog from "../../../UIComponents/CustomConfirmationDialog";

export default function PreviewPage() {
  const data = useSelector((store) => store.OfferReducer?.offerData);
  const previousOfferData = useSelector(
    (store) => store?.OfferReducer?.previousOfferDataForEditOffer
  );
  console.log(previousOfferData, "previousOfferData");
  const [isEdit, setIsEdit] = useState({
    offerDetails: true,
    qualifyingCriteria: true,
    createGame: true,
  });
  const [offerId, setOfferId] = useState(0);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
    const [confirmationText,setConfirmationText] = useState("");
  const [cancelText,setCancelText] = useState("");
  const [status,setStatus] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const BaseUrl = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {pathname} = useLocation();
  const makerCheckerPermission = getMakerCheckerPermission();
  const IdRef = useRef("");
  console.log(state, "state");
  const rewards = useSelector((store) => store?.OfferReducer?.allRewardTypes);
  const gameDataName =
    data?.createGame?.gameName === "Spin The Wheel"
      ? "spinTheWheelData"
      : data?.createGame?.gameName === "Slot Machine"
      ? "slotMachineData"
      : data?.createGame?.gameName === "Scratch Card"
      ? "scratchCardData"
      : data?.createGame?.gameName === "Trivia"
      ? "triviaData"
      : "";
const {id} = useParams();
  const envUrl = process.env.REACT_APP_OFFER_SUCCESS_URL;
  const OfferSuccessUrl = `${envUrl}/${offerId}`;

  const copyToClipboard = () => {
    const link = OfferSuccessUrl; // Replace with your actual offer link
    navigator.clipboard
      .writeText(link)
      .then(() => {
        // alert("Link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  console.log(data, "offerData");

  function findCashbackTypeKeyValuePairs(obj) {
    const cashbackTypeId = obj.cashback_type?.id;

    if (!cashbackTypeId) {
      return {};
    }

    return Object.entries(obj).reduce((result, [key, value]) => {
      if (key.startsWith(cashbackTypeId)) {
        const newKey = key.replace(`${cashbackTypeId}_`, "");
        result[newKey] = value;
      }
      return result;
    }, {});
  }

  // function findEditedFields(initialData, editedData) {
  //   const changes = {};
  
  //   function compareObjects(initial, edited, currentChanges, path) {
  //     let hasChanges = false;
  //     let hasNestedChanges = false;
  
  //     for (const key in edited) {
  //       const newPath = path ? `${path}.${key}` : key;
  
  //       if (Array.isArray(edited[key])) {
  //         if (JSON.stringify(initial[key]) !== JSON.stringify(edited[key])) {
  //           // For arrays, if any element has changed, include the whole array
  //           currentChanges[key] = edited[key];
  //           hasChanges = true;
  //           hasNestedChanges = true;
  //         }
  //       } else if (typeof edited[key] === 'object' && edited[key] !== null) {
  //         if (initial.hasOwnProperty(key)) {
  //           currentChanges[key] = {};
  //           const nestedHasChanges = compareObjects(initial[key], edited[key], currentChanges[key], newPath);
  
  //           if (nestedHasChanges) {
  //             // If nested object has changes, include the entire parent object
  //             currentChanges[key] = edited[key];
  //             hasChanges = true;
  //             hasNestedChanges = true;
  //           } else {
  //             delete currentChanges[key];
  //           }
  //         } else {
  //           currentChanges[key] = edited[key];
  //           hasChanges = true;
  //           hasNestedChanges = true;
  //         }
  //       } else if (initial[key] !== edited[key]) {
  //         // For primitive value changes, mark that parent should be included fully
  //         currentChanges[key] = edited[key];
  //         hasChanges = true;
  //         hasNestedChanges = true;
  //       }
  //     }
  
  //     // If any nested changes were detected, replace current changes with entire edited object
  //     if (hasNestedChanges && path) {
  //       return true;
  //     }
  
  //     return hasChanges;
  //   }
  
  //   // Compare the entire object structure
  //   const hasTopLevelChanges = compareObjects(initialData, editedData, changes, '');
  
  //   // Special handling for game changes to ensure entire game object is included
  //   if (changes.hasOwnProperty('game')) {
  //     changes.game = editedData.game;
  //   }
  
  //   // For each key in changes, if it's an object with changes, include the entire object from editedData
  //   for (const key in changes) {
  //     if (typeof changes[key] === 'object' && changes[key] !== null) {
  //       if (JSON.stringify(initialData[key]) !== JSON.stringify(editedData[key])) {
  //         changes[key] = editedData[key];
  //       }
  //     }
  //   }
  
  //   return changes;
  // }

  function findEditedFields(initialData, editedData) {
    const changes = {};
  
    // Function to clean object by removing null/undefined keys
    function cleanObject(obj) {
      if (Array.isArray(obj)) {
        return obj.map(item => typeof item === 'object' && item !== null ? cleanObject(item) : item);
      }
  
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }
  
      const cleaned = {};
      for (const key in obj) {
        // Skip keys named "null" or "undefined"
        if (key === 'null' || key === 'undefined') continue;
  
        const value = obj[key];
        if (typeof value === 'object' && value !== null) {
          cleaned[key] = cleanObject(value);
        } else {
          cleaned[key] = value;
        }
      }
      return cleaned;
    }
  
    function compareObjects(initial, edited, currentChanges, path) {
      let hasChanges = false;
      let hasNestedChanges = false;
  
      // Clean both objects before comparison
      const cleanedInitial = cleanObject(initial);
      const cleanedEdited = cleanObject(edited);
  
      for (const key in cleanedEdited) {
        const newPath = path ? `${path}.${key}` : key;
  
        if (Array.isArray(cleanedEdited[key])) {
          if (JSON.stringify(cleanedInitial[key]) !== JSON.stringify(cleanedEdited[key])) {
            currentChanges[key] = cleanedEdited[key];
            hasChanges = true;
            hasNestedChanges = true;
          }
        } else if (typeof cleanedEdited[key] === 'object' && cleanedEdited[key] !== null) {
          if (cleanedInitial.hasOwnProperty(key)) {
            currentChanges[key] = {};
            const nestedHasChanges = compareObjects(cleanedInitial[key], cleanedEdited[key], currentChanges[key], newPath);
            
            if (nestedHasChanges) {
              currentChanges[key] = cleanedEdited[key];
              hasChanges = true;
              hasNestedChanges = true;
            } else {
              delete currentChanges[key];
            }
          } else {
            currentChanges[key] = cleanedEdited[key];
            hasChanges = true;
            hasNestedChanges = true;
          }
        } else if (cleanedInitial[key] !== cleanedEdited[key]) {
          currentChanges[key] = cleanedEdited[key];
          hasChanges = true;
          hasNestedChanges = true;
        }
      }
  
      if (hasNestedChanges && path) {
        return true;
      }
  
      return hasChanges;
    }
  
    // Clean input data
    const cleanInitialData = cleanObject(initialData);
    const cleanEditedData = cleanObject(editedData);
  
    // Compare the entire object structure
    const hasTopLevelChanges = compareObjects(cleanInitialData, cleanEditedData, changes, '');
  
    // Special handling for game changes
    if (changes.hasOwnProperty('game')) {
      changes.game = cleanObject(editedData.game);
    }
  
    // For each key in changes, if it's an object with changes, include the entire cleaned object from editedData
    for (const key in changes) {
      if (typeof changes[key] === 'object' && changes[key] !== null) {
        if (JSON.stringify(cleanObject(initialData[key])) !== JSON.stringify(cleanObject(editedData[key]))) {
          changes[key] = cleanObject(editedData[key]);
        }
      }
    }
  
    return changes;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  function removeUndefinedKeys(data) {
    // Handle objects
    if (data && typeof data === 'object' && !Array.isArray(data)) {
        const newObj = {};
        
        for (const [key, value] of Object.entries(data)) {
            // Skip keys named 'undefined'
            if (key !== 'undefined') {
                newObj[key] = removeUndefinedKeys(value);
            }
        }
        return newObj;
    }
    
    // Handle arrays
    if (Array.isArray(data)) {
        return data.map(item => removeUndefinedKeys(item));
    }
    
    // Return non-object values as is
    return data;
}

function removeNullKeys(obj) {
  if (obj === null || typeof obj !== 'object') {
      return obj;
  }

  if (Array.isArray(obj)) {
      return obj.map(item => removeNullKeys(item));
  }

  return Object.fromEntries(
      Object.entries(obj)
          .filter(([_, value]) => value !== null)
          .map(([key, value]) => [key, removeNullKeys(value)])
  );
}
// function getRewardLimitsFunction(data) {
//   return data?.rewardLimits
//       ?.map(item => {
//           // Remove empty keys from each object
//           let cleanedItem = Object.keys(item)
//               .filter(key => item[key] !== "")
//               .reduce((acc, key) => {
//                   acc[key] = item[key];
//                   return acc;
//               }, {});

//           // Check if all limit keys are empty
//           const hasLimits = ["daily_limit", "weekly_limit", "monthly_limit", "overall_limit"]
//               .some(limitKey => cleanedItem[limitKey] !== undefined);

//           // Only return the object if it has non-empty limits
//           return hasLimits ? cleanedItem : null;
//       })
//       .filter(item => item !== null); // Remove any objects that were null (all limits were empty)
// }
  function getRewardLimitsFunction(reward) {
    return reward?.rewardLimits
        ?.map(item => {
            // Remove empty keys from each object
            let cleanedItem = Object.keys(item)
                .filter(key => item[key] !== "")
                .reduce((acc, key) => {
                    acc[key] = item[key];
                    return acc;
                }, {});

            // Check if all limit keys are empty
            const hasLimits = ["daily_limit", "weekly_limit", "monthly_limit", "overall_limit"]
                .some(limitKey => cleanedItem[limitKey] !== undefined);

            // Only return the object if it has non-empty limits
            return hasLimits ? cleanedItem : null;
        })
        .filter(item => item !== null).filter(item => {
            // Check conditions for user level
            if (item.limit_level === "user") {
                if(item?.entity_type === "reward_count"){
                    return reward?.user_level_limit === true
                }
                if(item?.entity_type === "reward_value"){
                    return reward?.user_level_value === true
                }
            }
            

            // Check conditions for product level
            if (item.limit_level === "product") {
                if(item?.entity_type === "reward_count"){
                    return reward?.product_level_limit === true
                }
                if(item?.entity_type === "reward_value"){
                    return reward?.product_level_value === true
                }
            }

            return false; // Filter out any items that don't match the conditions
        });
  }


  const handleCreateOffer = () => {
    const offerData = {
      productId: data?.offerDetails?.product,
      merchantId: data?.offerDetails?.merchant,
      name: data?.offerDetails?.title,
      description: data?.offerDetails?.description,
      startsAt: formatDate(data?.offerDetails?.startDate),
      endsAt: formatDate(data?.offerDetails?.endDate),
      lockingPeriod: data?.offerDetails?.lockingPeriod,
      expiryPeriod: data?.offerDetails?.expiryPeriod,
      termsAndConditions: data?.offerDetails?.termsAndConditions,
      initiativeId: data?.offerDetails?.offerInitiative?.id,
      coverImageId: data?.offerDetails?.coverPhotoId,
      earnRuleSetId: data?.setQualifyingCriteria?.linkToRule,
      collectionsIds: data?.setQualifyingCriteria?.linkToCohort,
      game: {
        game_type_id: data?.createGame?.game_type_id,
        rewards: data?.createGame?.game?.[gameDataName]?.map((reward) => {
          const rewardData = {
            reward_type_id: rewards?.find(
              (item) => item?.name == reward?.reward_type?.name
            )?.id,
            // title: reward?.title,
            win_probability: +reward?.win_probability_percentage?.toFixed(2),
            win_probability_factor : +reward?.win_probability,
            limit: reward?.reward_limit?true:false,
            reward_details: {
              reward_type: reward?.reward_type,
              cashback_type: reward?.cashback_type,
              ...findCashbackTypeKeyValuePairs(reward),
            },
            design_details: {
              after_play: data?.createGame?.afterPlay?.[gameDataName]?.find(
                (item) => item?.reward_type?.id === reward?.reward_type?.id
              ),
            },
          };
  
          // Add rewardLimits only if `limit` is true
          if (reward?.reward_limit && getRewardLimitsFunction(reward)?.length > 0) {
            rewardData.rewardLimits =getRewardLimitsFunction(reward) ;
          }
  
          return rewardData;
        }),
        design: {
          before_play: removeNullKeys(data?.createGame?.beforePlay?.[gameDataName]),
          // after_play: data?.createGame?.afterPlay?.[gameDataName],
        },
        details: {},
      },
    };
    const finalOfferData = removeUndefinedKeys(offerData);
    console.log(finalOfferData, "createOffer");
    const token = getToken();
  
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(`${BaseUrl}/api/create-offer`, finalOfferData, config)
      .then((res) => {
        dispatch({ type: clearCreateOfferData });
        console.log(res.data, "offerCreated");
        setOfferId(res.data.offerId);
        handleOpen();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message);
      });
  };
  
  const handleApproveOffer = (approvalType) => {
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = {};
    if (approvalType === "deny") {
      body.action = "DENY";
    } else if (approvalType === "approve") {
      body.action = "APPROVE";
    } else {
      return;
    }
    axios
      .post(
        `${BaseUrl}/api/submit-approval/${data?.offerDetails?.offerHistoryId}`,
        body,
        config
      )
      .then((res) => {
        console.log(res.data);
        console.log("Offer Approved");
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate("/view-offers");
        }, 800);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  const getEditedData = ()=>{

    const offerData = {
      productId: data?.offerDetails?.product,
      merchantId: data?.offerDetails?.merchant,
      name: data?.offerDetails?.title,
      description: data?.offerDetails?.description,
      startsAt: formatDate(data?.offerDetails?.startDate),
      endsAt: formatDate(data?.offerDetails?.endDate),
      lockingPeriod: data?.offerDetails?.lockingPeriod,
      expiryPeriod: data?.offerDetails?.expiryPeriod,
      termsAndConditions: data?.offerDetails?.termsAndConditions,
      initiativeId: data?.offerDetails?.offerInitiative?.id,
      coverImageId: data?.offerDetails?.coverPhotoId,
      earnRuleSetId: data?.setQualifyingCriteria?.linkToRule,
      collectionsIds: data?.setQualifyingCriteria?.linkToCohort,
      game: {
        game_type_id: data?.createGame?.game_type_id,
        rewards: data?.createGame?.game?.[gameDataName]?.map((reward) => {
          const rewardData = {
            reward_type_id: rewards?.find(
              (item) => item?.name == reward?.reward_type?.name
            )?.id,
            id:reward?.id,
            win_probability: +reward?.win_probability_percentage?.toFixed(2),
            win_probability_factor : +reward?.win_probability,
            limit: reward?.reward_limit?true:false,
            reward_details: {
              reward_type: reward?.reward_type,
              cashback_type: reward?.cashback_type,
              ...findCashbackTypeKeyValuePairs(reward),
            },
            design_details: {
              after_play: data?.createGame?.afterPlay?.[gameDataName]?.find(
                (item) => item?.reward_type?.id === reward?.reward_type?.id
              ),
            },
          };
  
          // Add rewardLimits only if `limit` is true
          if (reward?.reward_limit && getRewardLimitsFunction(reward)?.length > 0) {
            rewardData.rewardLimits = getRewardLimitsFunction(reward) ;
          }
  
          return rewardData;
        }),
        design: {
          before_play: data?.createGame?.beforePlay?.[gameDataName],
          // after_play: data?.createGame?.afterPlay?.[gameDataName],
        },
        details: {},
      },
    };
    const previousData = {
      productId: previousOfferData?.offerDetails?.product,
      merchantId: previousOfferData?.offerDetails?.merchant,
      name: previousOfferData?.offerDetails?.title,
      description: previousOfferData?.offerDetails?.description,
      startsAt: formatDate(previousOfferData?.offerDetails?.startDate),
      endsAt: formatDate(previousOfferData?.offerDetails?.endDate),
      lockingPeriod: previousOfferData?.offerDetails?.lockingPeriod,
      expiryPeriod: previousOfferData?.offerDetails?.expiryPeriod,
      termsAndConditions: previousOfferData?.offerDetails?.termsAndConditions,
      initiativeId: previousOfferData?.offerDetails?.offerInitiative?.id,
      coverImageId: previousOfferData?.offerDetails?.coverPhotoId,
      earnRuleSetId: previousOfferData?.setQualifyingCriteria?.linkToRule,
      collectionsIds: previousOfferData?.setQualifyingCriteria?.linkToCohort,
      game: {
        game_type_id: previousOfferData?.createGame?.game_type_id,
        rewards: previousOfferData?.createGame?.game?.[gameDataName]?.map((reward) => {
          const rewardData = {
            reward_type_id: rewards?.find(
              (item) => item?.name == reward?.reward_type?.name
            )?.id,
            id:reward?.id,
            win_probability: +reward?.win_probability_percentage?.toFixed(2),
            win_probability_factor : +reward?.win_probability,
            limit: reward?.reward_limit?true:false,
            reward_details: {
              reward_type: reward?.reward_type,
              cashback_type: reward?.cashback_type,
              ...findCashbackTypeKeyValuePairs(reward),
            },
            design_details: {
              after_play: previousOfferData?.createGame?.afterPlay?.[gameDataName]?.find(
                (item) => item?.reward_type?.id === reward?.reward_type?.id
              ),
            },
          };
    
          // Add rewardLimits only if `limit` is true
          if (reward?.reward_limit) {
            rewardData.rewardLimits = reward?.rewardLimits;
          }
    
          return rewardData;
        }),
        design: {
          before_play: previousOfferData?.createGame?.beforePlay?.[gameDataName],
          after_play: previousOfferData?.createGame?.afterPlay?.[gameDataName],
        },
        details: {},
      },
    };
    const changedData = findEditedFields(previousData,offerData);
    console.log(previousData,offerData,changedData, "changedData");

    // h
    if (Object.keys(changedData).length > 0){
      if(data?.offerDetails?.activeTab === "Pending" || data?.offerDetails?.activeTab === "Denied"){
        // changedData.source = data?.offerDetails?.activeTab === "Pending" ? "PENDING" :data?.offerDetails?.activeTab==="Denied"? "DENIED":"";
        changedData.historyId = data?.offerDetails?.offerHistoryId;
        console.log(changedData, "source");
      }
      changedData.source = data?.offerDetails?.activeTab === "Pending" ? "PENDING" :data?.offerDetails?.activeTab==="Denied"? "DENIED":"";
      // changedData.historyId = data?.offerDetails?.offerHistoryId;
      handleEditOffer(changedData, id);
    }else{
      toast.warning("No changes made");
    }
  }

  const handleEditOffer = (editedData, id) =>{
    const token = getToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  
    axios
      .post(`${BaseUrl}/api/admin/request-offer-approval/${id}`, editedData, config)
      .then((res) => {
        console.log(res.data, "edited");
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate("/view-offers");
        }, 800);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
      });
  
    // dispatch({ type: editOfferSuccess,});
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          onClick: (event) => event.stopPropagation(),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "425px",
            bgcolor: "background.paper",
            border: "2px solid transparent",
            boxShadow: 24,
            p: 4,
            borderRadius: "12px",
          }}
        >
          <Button
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer",
              minWidth: "0px",
            }}
            onClick={() => {
              handleClose();
              navigate("/view-offers");
            }}
          >
            <img src={closeBtnLogo} alt="" />
          </Button>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                height: "66px",
                width: "66px",
                backgroundColor: "#42EE281A",
                borderRadius: "18px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Box
                sx={{
                  height: "33px",
                  width: "33px",
                  backgroundColor: "#7AD16C",
                  borderRadius: "50%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <img src={tick} alt="" />
              </Box>
            </Box>
          </Box>
          <Box mt={"24px"}>
            <Typography
              sx={{
                fontFamily: "Lato",
                fontSize: "24px",
                fontWeight: 600,
                lineHeight: "36px",
                color: "#0B1B32",
                textAlign: "center",
              }}
            >
              Offer sent for approval successfully!
            </Typography>
          </Box>
          <Box
            mt={"29px"}
            mb={"2px"}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={"32px"}
              border="1px solid gray"
              borderRadius="8px"
              // padding="0px 12px"
              paddingLeft="8px"
              width="373px"
            >
              <Typography
                variant="body1"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                sx={{
                  fontFamily: "Lato",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#1D1F2C",
                }}
              >
                {OfferSuccessUrl}
              </Typography>
              <Button
                onClick={copyToClipboard}
                sx={{
                  display: "block",
                  marginLeft: "auto",
                  backgroundColor: "#F9F9FC",
                  height: "32px",
                  color: "#003D86",
                  width: "93px",
                  padding: "4px",
                  minWidth: "110px",
                  borderRadius: "0px 8px 8px 0px",
                  "&:hover": {
                    backgroundColor: "#F9F9FC",
                    opacity: 1,
                  },
                }}
              >
                <Box
                  display="flex"
                  gap="5px"
                  height="15px"
                  // width="150px"
                  alignItems="center"
                  paddingLeft="5px"
                  textTransform="capitalize"
                >
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#003D86",
                      ml: "5px",
                    }}
                  >
                    Copy
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontFamily: "Lato",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#003D86",
                    }}
                  >
                    {" "}
                    Link
                  </Typography>{" "}
                  <img src={link2} alt="" />
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <OfferDetailsPage showEdit={true} disableEditButton={state?.offerId} />
      <SetQualifyingCriteria showEdit={true} disableEditButton={state?.offerId}/>
      <CreateGamePage showEdit={true} disableEditButton={state?.offerId}/>
      {/* <ToastContainer /> */}
      <CustomConfirmationDialog
        open={isOpen}
        setOpen={setIsOpen}
        status={status}
        createOffer={() => handleCreateOffer()}
        approveById={() => handleApproveOffer("approve")}
        denyById={() => handleApproveOffer("deny")}
        editOffer={() => getEditedData()}
        prop={state?.offerId}
        message={message}
        confirmationText={confirmationText}
        cancelText={cancelText}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "16px",
            my: "20px",
            mx: "20px",
            pb: "30px",
          }}
        >
          <Button
            sx={{
              width: "147px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
              display: state?.offerId ? "none" : "block",
            }}
            // onClick={() => navigate(-1)}
            onClick={()=>{
              if (pathname.includes("edit-offers")) {
                navigate(`/offers/edit-offers/create-game/${id}`, {
                  state: {
                    activeStep: 3,
                  },
                });
              } else {
                navigate("/offers/create-offers/create-game",{
                  state: {
                    activeStep: 3,
                  },
                });
              }
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          <Button
            sx={{
              width: "157px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
              display: pathname.includes("edit-offers") && makerCheckerPermission?.maker? "block" : "none",
            }}
            onClick={() => {
              setIsOpen(true);
              setMessage("Are you sure you want to send this edit request to the admin for approval.");
              setConfirmationText("Yes, Send");
              setCancelText("Go Back");
              setStatus("edit");
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
            >
              Edit Offer
            </Typography>
          </Button>
          <Button
            sx={{
              width: "157px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
              display: state?.offerId || pathname.includes("edit-offers") || !makerCheckerPermission?.maker ? "none" : "block",
            }}
            onClick={() => {
              setIsOpen(true);
              setMessage("Are you sure you want to send this offer request to the admin for approval.");
              setConfirmationText("Yes, Send");
              setCancelText("Go Back");
              setStatus("create");
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
              // onClick={handleCreateOffer}
            >
              Submit for Approval
            </Typography>
          </Button>
          <Button
            sx={{
              width: "147px",
              height: "30px",
              backgroundColor: "#E8F0F9",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#E8F0F9",
              },
              display: state?.offerId==="approve" && makerCheckerPermission?.checker? "block" : "none",
            }}
            onClick={() => {
              setIsOpen(true);
              setMessage("Are you sure you want to deny approval of this offer request?");
              setConfirmationText("Yes, Deny");
              setCancelText("Go Back");
              setStatus("deny");
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#003D86",
                textTransform: "capitalize",
              }}
              // onClick={()=>handleApproveOffer("deny")}
            >
              Deny
            </Typography>
          </Button>
          <Button
            sx={{
              width: "147px",
              height: "30px",
              backgroundColor: "#003D86",
              padding: "7px 12px",
              borderRadius: "6px",
              "&:hover": {
                backgroundColor: "#003D86",
              },
              display: state?.offerId==="approve" && makerCheckerPermission?.checker ? "block" : "none",
            }}
            onClick={() => {
              setIsOpen(true);
              setMessage("Are you sure you want to approve this offer request?");
              setConfirmationText("Yes, Approve");
              setCancelText("Go Back");
              setStatus("approve");
            }}
          >
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "16.8px",
                color: "#FFFFFF",
                textTransform: "capitalize",
              }}
              // onClick={()=>handleApproveOffer("approve")}
            >
              Approve
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
}
